import React, { useEffect } from 'react';
import { Link, graphql } from "gatsby"
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"
import Layout from "../components/layout"
import {Helmet} from "react-helmet"
import Seo from "../components/seo"

  

const Comarques = ({ data, location }) => {
    var comarques=data.comarques.edges
    const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={location} tigitle={siteTitle}>
    <Helmet>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.min.js" integrity="sha384-cuYeSxntonz0PPNlHhBs68uyIAVpIIOZZ5JqeqvYYIcEL727kskC66kF92t6Xl2V" crossorigin="anonymous"></script>
    </Helmet>
    <Seo title="Totes les poblacións" />
    <article
      className="global-wrapper blog-post"
      itemScope
      itemType="http://schema.org/Article"
    >
    <h1 itemProp="headline">Esdeveniments per Comarca</h1>
        <section className="row">
        {comarques.map(node => (
          <div class="col-xs-12 col-md-4 mb-4" key={node.artist.id}>
              <div class="card">
                <img class="card-img-top" src={`/imgs/comarques/${node.artist.slug}.jpg`} alt={node.artist.name} />
                <div class="card-body">
                  <Link to={`/comarca/${node.artist.slug}/`}>
                  <h3 class="card-title">{node.artist.name}</h3>
                  </Link>
                </div>
              </div>
          </div>
        ))}
        </section>
    </article>
    </Layout>
  )
}

export default Comarques

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    comarques: allMysqlComarques (sort: { fields: [name], order: ASC }) {
      edges {
        artist: node {
          id
          name
          slug
        }
      }
    }
  }
`
